<template>
  <b-navbar toggleable="lg" fixed="top">
    <b-navbar-brand
      class="d-flex align-items-center"
      to="/#welcome"
      data-menuanchor="welcome"
      href="#welcome"
      ><img
        :src="require(`@/assets/svgs/${logo}`)"
        class="d-inline-block align-top"
        alt="Logo"
        width="40"
        height="40"
      />Na Nuad - Thai</b-navbar-brand
    >
    <button
      :class="visible ? 'is-active' : 'collapsed'"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="nav-collapse"
      @click="visible = !visible"
      class="navbar-toggler hamburger hamburger--collapse"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>

    <b-collapse id="nav-collapse" v-model="visible" is-nav>
      <b-navbar-nav class="ml-auto mr-4" id="myMenu">
        <b-nav-item to="/#massagen" data-menuanchor="massagen" href="#massagen"
          >Massagen</b-nav-item
        >
        <b-nav-item to="/#team" data-menuanchor="team" href="#team"
          >Team</b-nav-item
        >
        <b-nav-item to="/#news" data-menuanchor="news" href="#news"
          >Neuigkeiten</b-nav-item
        >
        <b-nav-item to="/#preise" data-menuanchor="preise" href="#preise"
          >Preise</b-nav-item
        >
        <b-nav-item to="/#kontakt" data-menuanchor="kontakt" href="#kontakt"
          >Kontakt</b-nav-item
        >
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "mainnaviagtion",
  data() {
    return {
      logo: "buddha.svg",
      visible: false
    };
  }
};
</script>
