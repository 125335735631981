<template>
  <footer class="container-fluid">
    <b-container>
      <b-row>
        <b-col lg="6" class="my-auto">
          <h3>Na Nuad-Thai</h3>
          <b-row>
            <b-col lg="6">
              <ul>
                <li>
                  <router-link to="/" data-menuanchor="news" href="#news"
                    >News</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/"
                    data-menuanchor="massagen"
                    href="#massagen"
                    >Massagen</router-link
                  >
                </li>
                <li>
                  <router-link to="/" data-menuanchor="preise" href="#preise"
                    >Preise</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/"
                    data-menuanchor="gutscheine"
                    href="#gutscheine"
                    >Gutscheine</router-link
                  >
                </li>
              </ul>
            </b-col>
            <b-col lg="6">
              <ul>
                <li>
                  <router-link to="/datenschutz">Datenschutz</router-link>
                </li>
                <li><router-link to="/impressum">Impressum</router-link></li>
              </ul>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2"
              ><b-img
                :src="require(`@/assets/svgs/location.svg`)"
                width="40"
                height="40"
                center
              ></b-img
            ></b-col>
            <b-col cols="10"
              ><p>
                Fa. Thai Consult - Inh. Treenoot Töppe - Exterstr. 11<br />
                32756 Detmold -
                <strong
                  >Telefon:
                  <a href="tel:052313033270">05231 – 30 33 270</a></strong
                >
              </p></b-col
            >
          </b-row>
          <b-row>
            <b-col cols="2"
              ><b-img
                :src="require(`@/assets/svgs/clock.svg`)"
                width="40"
                height="40"
                center
              ></b-img
            ></b-col>
            <b-col cols="10">
              <p>
                <strong>Öffnungszeiten:</strong><br />
                Montag bis Freitag von 9:30 - 19:30 Uhr<br />
                Samstag von 9:30 - 18:00
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <l-map
            style="height: 400px; width: 100%; z-index:0;"
            :zoom="zoom"
            :center="center"
            :options="{ zoomControl: false, scrollWheelZoom: false }"
          >
            <l-control-zoom position="bottomright"></l-control-zoom>
            <l-tile-layer :url="url"></l-tile-layer>
            <l-marker :lat-lng="markerLatLng"></l-marker>
          </l-map>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import { LMap, LTileLayer, LControlZoom, LMarker } from "vue2-leaflet";

export default {
  name: "mainfooter",
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LMarker
  },
  data() {
    return {
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 17,
      center: [51.933571, 8.8799],
      bounds: null,
      markerLatLng: [51.933571, 8.8799]
    };
  }
};
</script>
