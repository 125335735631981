<template>
  <div class="datenschutz">
    <Navigation />
    <div class="wrapper">
      <b-container>
        <b-row>
          <b-col class="py-5">
            <h1 class="display-2">Datenschutzerklärung</h1>
            <h3 id="dsg-general-intro"></h3>
            <p>
              Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und
              Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend
              kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm
              verbundenen Webseiten, Funktionen und Inhalte sowie externen
              Onlinepräsenzen, wie z.B. unser Social Media Profile auf
              (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
              Hinblick auf die verwendeten Begrifflichkeiten, wie z.B.
              „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
              Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).<br />
              <br />
            </p>
            <h3 id="dsg-general-controller">Verantwortlicher</h3>
            <p>
              <span class="tsmcontroller"
                >Treenoot Töppe<br />
                Fa. Thai Consult<br />
                Exterstr. 11<br />
                32756 Detmold<br />
                <br />
                Telefon: 05231 – 30 33 270<br />
                www.thai-massage-detmold.de</span
              >
            </p>
            <h3 id="dsg-general-datatype">Arten der verarbeiteten Daten:</h3>
            <p>
              - Bestandsdaten (z.B., Namen, Adressen).<br />
              - Kontaktdaten (z.B., E-Mail, Telefonnummern).<br />
              - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).<br />
              - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).<br />
              - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
              IP-Adressen).<br />
            </p>
            <h3 id="dsg-general-datasubjects">
              Kategorien betroffener Personen
            </h3>
            <p>
              Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen
              wir die betroffenen Personen zusammenfassend auch als
              „Nutzer“).<br />
            </p>
            <h3 id="dsg-general-purpose">Zweck der Verarbeitung</h3>
            <p>
              - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
              Inhalte.<br />
              - Beantwortung von Kontaktanfragen und Kommunikation mit
              Nutzern.<br />
              - Sicherheitsmaßnahmen.<br />
              - Reichweitenmessung/Marketing<br />
              <span class="tsmcom"></span>
            </p>
            <h3 id="dsg-general-terms">Verwendete Begrifflichkeiten</h3>
            <p>
              „Personenbezogene Daten“ sind alle Informationen, die sich auf
              eine identifizierte oder identifizierbare natürliche Person (im
              Folgenden „betroffene Person“) beziehen; als identifizierbar wird
              eine natürliche Person angesehen, die direkt oder indirekt,
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
              zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
              (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen
              identifiziert werden kann, die Ausdruck der physischen,
              physiologischen, genetischen, psychischen, wirtschaftlichen,
              kulturellen oder sozialen Identität dieser natürlichen Person
              sind.<br />
              <br />
              „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter
              Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
              Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit
              und umfasst praktisch jeden Umgang mit Daten.<br />
              <br />
              „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in
              einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung
              zusätzlicher Informationen nicht mehr einer spezifischen
              betroffenen Person zugeordnet werden können, sofern diese
              zusätzlichen Informationen gesondert aufbewahrt werden und
              technischen und organisatorischen Maßnahmen unterliegen, die
              gewährleisten, dass die personenbezogenen Daten nicht einer
              identifizierten oder identifizierbaren natürlichen Person
              zugewiesen werden.<br />
              <br />
              „Profiling“ jede Art der automatisierten Verarbeitung
              personenbezogener Daten, die darin besteht, dass diese
              personenbezogenen Daten verwendet werden, um bestimmte persönliche
              Aspekte, die sich auf eine natürliche Person beziehen, zu
              bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung,
              wirtschaftliche Lage, Gesundheit, persönliche Vorlieben,
              Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
              Ortswechsel dieser natürlichen Person zu analysieren oder
              vorherzusagen.<br />
              <br />
              Als „Verantwortlicher“ wird die natürliche oder juristische
              Person, Behörde, Einrichtung oder andere Stelle, die allein oder
              gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
              von personenbezogenen Daten entscheidet, bezeichnet.<br />
              <br />
              „Auftragsverarbeiter“ eine natürliche oder juristische Person,
              Behörde, Einrichtung oder andere Stelle, die personenbezogene
              Daten im Auftrag des Verantwortlichen verarbeitet.<br />
            </p>
            <h3 id="dsg-general-legalbasis">Maßgebliche Rechtsgrundlagen</h3>
            <p>
              Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die
              Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die
              Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird,
              gilt Folgendes: Die Rechtsgrundlage für die Einholung von
              Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die
              Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
              Leistungen und Durchführung vertraglicher Maßnahmen sowie
              Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die
              Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
              rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und
              die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
              berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den
              Fall, dass lebenswichtige Interessen der betroffenen Person oder
              einer anderen natürlichen Person eine Verarbeitung
              personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
              lit. d DSGVO als Rechtsgrundlage.
            </p>
            <h3 id="dsg-general-securitymeasures">Sicherheitsmaßnahmen</h3>
            <p>
              Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung
              des Stands der Technik, der Implementierungskosten und der Art,
              des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie
              der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des
              Risikos für die Rechte und Freiheiten natürlicher Personen,
              geeignete technische und organisatorische Maßnahmen, um ein dem
              Risiko angemessenes Schutzniveau zu gewährleisten.<br />
              <br />
              Zu den Maßnahmen gehören insbesondere die Sicherung der
              Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
              Kontrolle des physischen Zugangs zu den Daten, als auch des sie
              betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der
              Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
              eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
              Löschung von Daten und Reaktion auf Gefährdung der Daten
              gewährleisten. Ferner berücksichtigen wir den Schutz
              personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl
              von Hardware, Software sowie Verfahren, entsprechend dem Prinzip
              des Datenschutzes durch Technikgestaltung und durch
              datenschutzfreundliche Voreinstellungen (Art. 25 DSGVO).<br />
            </p>
            <h3 id="dsg-general-coprocessing">
              Zusammenarbeit mit Auftragsverarbeitern und Dritten
            </h3>
            <p>
              Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
              Personen und Unternehmen (Auftragsverarbeitern oder Dritten)
              offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf
              die Daten gewähren, erfolgt dies nur auf Grundlage einer
              gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an
              Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b
              DSGVO zur Vertragserfüllung erforderlich ist), Sie eingewilligt
              haben, eine rechtliche Verpflichtung dies vorsieht oder auf
              Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von
              Beauftragten, Webhostern, etc.). <br />
              <br />
              Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage
              eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht
              dies auf Grundlage des Art. 28 DSGVO.
            </p>
            <h3 id="dsg-general-thirdparty">Übermittlungen in Drittländer</h3>
            <p>
              Sofern wir Daten in einem Drittland (d.h. außerhalb der
              Europäischen Union (EU) oder des Europäischen Wirtschaftsraums
              (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von
              Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an
              Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer
              (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung,
              aufgrund einer rechtlichen Verpflichtung oder auf Grundlage
              unserer berechtigten Interessen geschieht. Vorbehaltlich
              gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder
              lassen wir die Daten in einem Drittland nur beim Vorliegen der
              besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h.
              die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien,
              wie der offiziell anerkannten Feststellung eines der EU
              entsprechenden Datenschutzniveaus (z.B. für die USA durch das
              „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller
              vertraglicher Verpflichtungen (so genannte
              „Standardvertragsklauseln“).
            </p>
            <h3 id="dsg-general-rightssubject">
              Rechte der betroffenen Personen
            </h3>
            <p>
              Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
              betreffende Daten verarbeitet werden und auf Auskunft über diese
              Daten sowie auf weitere Informationen und Kopie der Daten
              entsprechend Art. 15 DSGVO.<br />
              <br />
              Sie haben entsprechend. Art. 16 DSGVO das Recht, die
              Vervollständigung der Sie betreffenden Daten oder die Berichtigung
              der Sie betreffenden unrichtigen Daten zu verlangen.<br />
              <br />
              Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen,
              dass betreffende Daten unverzüglich gelöscht werden, bzw.
              alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der
              Verarbeitung der Daten zu verlangen.<br />
              <br />
              Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten,
              die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu
              erhalten und deren Übermittlung an andere Verantwortliche zu
              fordern. <br />
              <br />
              Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei
              der zuständigen Aufsichtsbehörde einzureichen.
            </p>
            <h3 id="dsg-general-revokeconsent">Widerrufsrecht</h3>
            <p>
              Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3
              DSGVO mit Wirkung für die Zukunft zu widerrufen
            </p>
            <h3 id="dsg-general-object">Widerspruchsrecht</h3>
            <p>
              Sie können der künftigen Verarbeitung der Sie betreffenden Daten
              nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der
              Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke
              der Direktwerbung erfolgen.
            </p>
            <h3 id="dsg-general-cookies">
              Cookies und Widerspruchsrecht bei Direktwerbung
            </h3>
            <p>
              Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern
              der Nutzer gespeichert werden. Innerhalb der Cookies können
              unterschiedliche Angaben gespeichert werden. Ein Cookie dient
              primär dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem
              das Cookie gespeichert ist) während oder auch nach seinem Besuch
              innerhalb eines Onlineangebotes zu speichern. Als temporäre
              Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, werden
              Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein
              Onlineangebot verlässt und seinen Browser schließt. In einem
              solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem
              Onlineshop oder ein Login-Status gespeichert werden. Als
              „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch
              nach dem Schließen des Browsers gespeichert bleiben. So kann z.B.
              der Login-Status gespeichert werden, wenn die Nutzer diese nach
              mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie
              die Interessen der Nutzer gespeichert werden, die für
              Reichweitenmessung oder Marketingzwecke verwendet werden. Als
              „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen
              Anbietern als dem Verantwortlichen, der das Onlineangebot
              betreibt, angeboten werden (andernfalls, wenn es nur dessen
              Cookies sind spricht man von „First-Party Cookies“).<br />
              <br />
              Wir können temporäre und permanente Cookies einsetzen und klären
              hierüber im Rahmen unserer Datenschutzerklärung auf.<br />
              <br />
              Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
              gespeichert werden, werden sie gebeten die entsprechende Option in
              den Systemeinstellungen ihres Browsers zu deaktivieren.
              Gespeicherte Cookies können in den Systemeinstellungen des
              Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
              Funktionseinschränkungen dieses Onlineangebotes führen.<br />
              <br />
              Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
              Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der
              Dienste, vor allem im Fall des Trackings, über die
              US-amerikanische Seite
              <a href="http://www.aboutads.info/choices/"
                >http://www.aboutads.info/choices/</a
              >
              oder die EU-Seite
              <a href="http://www.youronlinechoices.com/"
                >http://www.youronlinechoices.com/</a
              >
              erklärt werden. Des Weiteren kann die Speicherung von Cookies
              mittels deren Abschaltung in den Einstellungen des Browsers
              erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls
              nicht alle Funktionen dieses Onlineangebotes genutzt werden
              können.
            </p>
            <h3 id="dsg-general-erasure">Löschung von Daten</h3>
            <p>
              Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17
              und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt.
              Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich
              angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald
              sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der
              Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
              Sofern die Daten nicht gelöscht werden, weil sie für andere und
              gesetzlich zulässige Zwecke erforderlich sind, wird deren
              Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und
              nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die
              aus handels- oder steuerrechtlichen Gründen aufbewahrt werden
              müssen.<br />
              <br />
              Nach gesetzlichen Vorgaben in Deutschland, erfolgt die
              Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257
              Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen,
              Lageberichte, Buchungsbelege, Handelsbücher, für Besteuerung
              relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2
              und 3, Abs. 4 HGB (Handelsbriefe). <br />
              <br />
              Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung
              insbesondere für 7 J gemäß § 132 Abs. 1 BAO
              (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege,
              Geschäftspapiere, Aufstellung der Einnahmen und Ausgaben, etc.),
              für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei
              Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen,
              Telekommunikations-, Rundfunk- und Fernsehleistungen, die an
              Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und für die
              der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
            </p>
            <p></p>
            <h3 id="dsg-therapycoach">
              Therapeutische Leistungen und Coaching
            </h3>
            <p></p>
            <p>
              <span class="ts-muster-content"
                >Wir verarbeiten die Daten unserer Klienten und Interessenten
                und anderer Auftraggeber oder Vertragspartner (einheitlich
                bezeichnet als „Klienten“) entsprechend Art. 6 Abs. 1 lit. b)
                DSGVO, um ihnen gegenüber unsere vertraglichen oder
                vorvertraglichen Leistungen zu erbringen. Die hierbei
                verarbeiteten Daten, die Art, der Umfang und der Zweck und die
                Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem
                zugrundeliegenden Vertragsverhältnis. Zu den verarbeiteten Daten
                gehören grundsätzlich Bestands- und Stammdaten der Klienten
                (z.B., Name, Adresse, etc.), als auch die Kontaktdaten (z.B.,
                E-Mailadresse, Telefon, etc.), die Vertragsdaten (z.B., in
                Anspruch genommene Leistungen, Honorare, Namen von
                Kontaktpersonen, etc.) und Zahlungsdaten (z.B., Bankverbindung,
                Zahlungshistorie, etc.).<br />
                <br />
                Im Rahmen unserer Leistungen, können wir ferner besondere
                Kategorien von Daten gem. Art. 9 Abs. 1 DSGVO, insbesondere
                Angaben zur Gesundheit der Klienten, ggf. mit Bezug zu deren
                Sexualleben oder der sexuellen Orientierung, ethnischer Herkunft
                oder religiösen oder weltanschaulichen Überzeugungen,
                verarbeiten. Hierzu holen wir, sofern erforderlich, gem. Art. 6
                Abs. 1 lit. a., Art. 7, Art. 9 Abs. 2 lit. a. DSGVO eine
                ausdrückliche Einwilligung der Klienten ein und verarbeiten die
                besonderen Kategorien von Daten ansonsten zu Zwecken der
                Gesundheitsvorsorge auf Grundlage des Art. 9 Abs. 2 lit h.
                DSGVO, § 22 Abs. 1 Nr. 1 b. BDSG.<br />
                <br />
                Sofern für die Vertragserfüllung oder gesetzlich erforderlich,
                offenbaren oder übermitteln wir die Daten der Klienten im Rahmen
                der Kommunikation mit anderen Fachkräften, an der
                Vertragserfüllung erforderlicherweise oder typischerweise
                beteiligten Dritten, wie z.B. Abrechnungsstellen oder
                vergleichbare Dienstleister, sofern dies der Erbringung unserer
                Leistungen gem. Art. 6 Abs. 1 lit b. DSGVO dient, gesetzlich
                gem. Art. 6 Abs. 1 lit c. DSGVO vorgeschrieben ist, unseren
                Interessen oder denen der Klienten an einer effizienten und
                kostengünstigen Gesundheitsversorgung als berechtigtes Interesse
                gem. Art. 6 Abs. 1 lit f. DSGVO dient oder gem. Art. 6 Abs. 1
                lit d. DSGVO notwendig ist. um lebenswichtige Interessen der
                Klienten oder einer anderen natürlichen Person zu schützen oder
                im Rahmen einer Einwilligung gem. Art. 6 Abs. 1 lit. a., Art. 7
                DSGVO.<br />
                <br />
                Die Löschung der Daten erfolgt, wenn die Daten zur Erfüllung
                vertraglicher oder gesetzlicher Fürsorgepflichten sowie Umgang
                mit etwaigen Gewährleistungs- und vergleichbaren Pflichten nicht
                mehr erforderlich ist, wobei die Erforderlichkeit der
                Aufbewahrung der Daten alle drei Jahre überprüft wird; im
                Übrigen gelten die gesetzlichen Aufbewahrungspflichten.<br />
              </span>
            </p>
            <p></p>
            <h3 id="dsg-administration">
              Administration, Finanzbuchhaltung, Büroorganisation,
              Kontaktverwaltung
            </h3>
            <p></p>
            <p>
              <span class="ts-muster-content"
                >Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie
                Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung
                der gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei
                verarbeiten wir dieselben Daten, die wir im Rahmen der
                Erbringung unserer vertraglichen Leistungen verarbeiten. Die
                Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6
                Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden,
                Interessenten, Geschäftspartner und Websitebesucher betroffen.
                Der Zweck und unser Interesse an der Verarbeitung liegt in der
                Administration, Finanzbuchhaltung, Büroorganisation,
                Archivierung von Daten, also Aufgaben die der Aufrechterhaltung
                unserer Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und
                Erbringung unserer Leistungen dienen. Die Löschung der Daten im
                Hinblick auf vertragliche Leistungen und die vertragliche
                Kommunikation entspricht den, bei diesen
                Verarbeitungstätigkeiten genannten Angaben.<br />
                <br />
                Wir offenbaren oder übermitteln hierbei Daten an die
                Finanzverwaltung, Berater, wie z.B., Steuerberater oder
                Wirtschaftsprüfer sowie weitere Gebührenstellen und
                Zahlungsdienstleister.<br />
                <br />
                Ferner speichern wir auf Grundlage unserer
                betriebswirtschaftlichen Interessen Angaben zu Lieferanten,
                Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks
                späterer Kontaktaufnahme. Diese mehrheitlich
                unternehmensbezogenen Daten, speichern wir grundsätzlich
                dauerhaft.<br />
              </span>
            </p>
            <p></p>
            <h3 id="dsg-logfiles">Erhebung von Zugriffsdaten und Logfiles</h3>
            <p></p>
            <p>
              <span class="ts-muster-content"
                >Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
                berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
                Daten über jeden Zugriff auf den Server, auf dem sich dieser
                Dienst befindet (sogenannte Serverlogfiles). Zu den
                Zugriffsdaten gehören Name der abgerufenen Webseite, Datei,
                Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung
                über erfolgreichen Abruf, Browsertyp nebst Version, das
                Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte
                Seite), IP-Adresse und der anfragende Provider.<br />
                <br />
                Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
                Aufklärung von Missbrauchs- oder Betrugshandlungen) für die
                Dauer von maximal 7 Tagen gespeichert und danach gelöscht.
                Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich
                ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls
                von der Löschung ausgenommen.</span
              >
            </p>
            <a
              href="https://datenschutz-generator.de"
              class="dsg1-5"
              rel="nofollow"
              target="_blank"
              >Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas
              Schwenke</a
            >
            <h3 id="data" class="mt-3">
              Nutzung Ihrer Daten aus diesem Formular
            </h3>
            <p>
              Die personenbezogenen Daten, die Sie uns im Rahmen dieser
              Kontaktanfrage zur Verfügung stellen, werden nur für die
              Beantwortung Ihrer Anfrage bzw. Kontaktaufnahme und für die damit
              verbundene technische Administration verwendet. Die Weitergabe an
              Dritte findet nicht statt.
            </p>
            <p>
              Sie haben das Recht, eine erteilte Einwilligung mit Wirkung für
              die Zukunft jederzeit zu widerrufen. In diesem Fall werden Ihre
              personenbezogenen Daten umgehend gelöscht.
            </p>
            <p>
              Ihre personenbezogenen Daten werden auch ohne Ihren Widerruf
              gelöscht, wenn wir Ihre Anfrage bearbeitet haben oder Sie die hier
              erteilte Einwilligung zur Speicherung widerrufen. Dies geschieht
              auch, wenn die Speicherung aus sonstigen gesetzlichen Gründen
              unzulässig ist.
            </p>
            <p>
              Sie können sich jederzeit über die zu Ihrer Person gespeicherten
              Daten informieren.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "datenschutz",
  components: {
    Navigation,
    Footer
  }
};
</script>

<style scoped lang="scss">
#app {
  .wrapper {
    padding-top: 4.25rem;
    h1 {
      text-indent: 0.3rem;
    }
  }
}
</style>
